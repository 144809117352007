
blockquote {

  :before {
    content: '\201C';
  }

  :after {
    content: '\201D';
  }
  p {
      font-size: 2em;
  }
}
